import React from "react";
import { AppBar, Toolbar, Box, Typography, Stack, Button } from "@mui/material";
import { Link } from "react-router-dom";
import { useMsal } from "@azure/msal-react";
import Logo from "./logo-white.png";
const Header = () => {
  const { instance } = useMsal();

  return (
    <AppBar
      elevation={0}
      sx={{
        zIndex: (theme) => theme.zIndex.drawer + 1,
      }}
    >
      <Toolbar>
        <Box display="flex" flex={1} justifyContent="space-between">
          <Stack direction="row" spacing={1}>
            <Link to="/">
              <img src={Logo} height={60} alt="logo" />
            </Link>
            <Box
              mx={2}
              flex={1}
              display="flex"
              flexDirection="column"
              justifyContent="center"
            >
              <Typography variant="h6">Profit Analysis</Typography>
            </Box>
          </Stack>

          <Button
            variant="outlined"
            sx={{ color: (theme) => theme.palette.common.white }}
            onClick={() =>
              instance.logoutRedirect({
                postLogoutRedirectUri: "/",
              })
            }
          >
            Logout
          </Button>
        </Box>
      </Toolbar>
    </AppBar>
  );
};

export default Header;
